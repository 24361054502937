import styled from "@emotion/styled"

export const Icon = styled.a`
  color: var(--foreground);
  transition: 0.3s;

  &:hover {
    color: var(--foregroundOffset);
    cursor: pointer;
  }
`

const buttonProperties = `
  font-family: var(--primaryFont);
  letter-spacing: 0.05rem;
  height: 3rem;
  padding: 0 2rem;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  background-color: var(--foreground);
  transition: 0.3s;

  &:hover {
    background-color: var(--foregroundOffset);
    cursor: pointer;
  }
`

export const PrimaryButton = styled.button`
  ${buttonProperties}
`

export const PrimaryButtonInput = styled.input`
  ${buttonProperties}
`

export const BlurredContainer = styled.div`
  transition: 0.3s;
  & img {
    border-radius: 10px;
  }

  &:hover {
    filter: blur(3px);
    cursor: pointer;
  }
`

export const FlexibleRowContainer = styled.div`
  display: flex;
  column-gap: 2rem;
  row-gap: 2rem;
  flex-wrap: wrap;
`

export const PageTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0;
`
